<template>
  <div>
    <el-dialog
      top="12%"
      :close-on-click-modal="false"
      title="新增兑换码"
      :visible.sync="dialogVisible"
      width="444px"
      :modal-append-to-body="false"
      class="pop-dialog promo-add-dialog"
    >
      <el-form
        ref="addNewForm"
        style="width:240px"
        :rules="promoRules"
        :model="addNewForm"
        label-width="80px"
        label-position="left"
      >
        <el-form-item label="名称" required prop="name">
          <el-input
            style="width:240px"
            v-model="addNewForm.name"
            class="dialog-input"
            :validate-event="false"
            placeholder="输入兑换码名称"
          />
        </el-form-item>

        <el-form-item label="有效期">
          <el-select v-model="permenant" style="width:240px">
            <el-option :value="true" label="永久有效"></el-option>
            <el-option :value="false" label="固定时间"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="固定时间" v-if="!permenant" required>
          <el-date-picker
            v-model="dates"
            style="width:240px"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            @change="handleDateChange"
            :validate-event="false"
          ></el-date-picker>
        </el-form-item>

        <el-form-item label="可兑积分" required prop="amount">
          <el-input
            style="width:240px"
            v-model="addNewForm.amount"
            class="dialog-input"
            autocomplete="off"
            :validate-event="false"
            placeholder="输入积分数量"
            maxlength="3"
          />
        </el-form-item>

        <el-form-item label="可兑数量" required prop="usage_limit">
          <el-input
            style="width:240px"
            v-model="addNewForm.usage_limit"
            class="dialog-input"
            autocomplete="off"
            :validate-event="false"
            placeholder="输入可兑数量"
            maxlength="5"
          />
        </el-form-item>

        <el-form-item label="兑换码" required prop="code">
          <el-input
            style="width:240px"
            v-model="addNewForm.code"
            class="dialog-input"
            autocomplete="off"
            :validate-event="false"
            placeholder="输入兑换码"
            maxlength="12"
          >
            <span slot="suffix" @click="generatePromoCode">随机</span>
          </el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" class="confirm-button" @click="handleAddNew" size="medium" v-loading="btnLoading">确认</el-button>
        <el-button @click="hide" class="cancel-button">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import promoApi from "@/api/promotions";
import formValidations from "@/mixins/form-validations";

export default {
  name: "AddNewDialog",
  mixins: [formValidations],
  props: {
    updateList: {
      type: Function,
      default: () => {
        return Function();
      },
    },
  },

  data() {
    return {
      permenant: true,
      dates: [],
      addNewForm: {
        name: "",
        type: 1,
        code: "",
        amount: "",
        usage_limit: "",
        effective_at: "",
        expired_at: "",
        market: "US",
      },
      dialogVisible: false,
      btnLoading:false
    };
  },

  methods: {
    clear() {
      this.clearForm("addNewForm");
      this.dates = [];
    },

    show() {
      this.dialogVisible = true;
      this.clear();
    },

    hide() {
      this.dialogVisible = false;
      this.clear();
    },

    handleDateChange(val) {
      this.dates = val;
      if (val) {
        const startDate = val[0] + " 00:00:00";
        const endDate = val[1] + " 23:59:59";
        this.addNewForm.effective_at = startDate;
        this.addNewForm.expired_at = endDate;
      } else {
        (this.addNewForm.effective_at = ""), (this.addNewForm.expired_at = "");
      }
    },

    addNewPromo() {
      this.btnLoading=true;
      promoApi.addNewPromo(this.addNewForm).then((res) => {
        if (res.data.code === 10200) {
          this.$$success("添加成功！");
          this.hide();
          this.updateList();
        } else {
          this.$$error(res.data.message);
        }
        this.btnLoading=false
      });
    },

    generatePromoCode() {
      promoApi.codeGenerate().then((res) => {
        if (res.data.code === 10200) {
          this.addNewForm.code = res.data.data;
        } else {
          this.$$error(res.data.message);
        }
      });
    },

    handleAddNew() {
      this.validateForm("addNewForm", () => this.addNewPromo());
    },
  },
};
</script>
<style lang="less" >
.promo-add-dialog {
  .el-input__suffix-inner {
    color: black;
    text-decoration: underline;
    margin-right: 5px;
    font-weight: 500;
    &:hover {
      cursor: pointer;
    }
  }
}
</style>