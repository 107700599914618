import input from '@/uitls/inputCheck';

export default {
	data() {
		var checkIsNumber = (rule, value, callback) => {
			setTimeout(() => {
				const res = input.checkIsNumber(value);
				if (res !== -1) {
					callback(input.checkFormErrorMsg(res));
				}
                if (value <=0) {
                    callback('请输入大于零的数');
                }
				callback();
			}, 200);
		};
        var checkIsNumberMax = (rule, value, callback) => {
			setTimeout(() => {
				const res = input.checkIsNumberMax(value);
				if (res !== -1) {
					callback(input.checkFormErrorMsg(res));
				}
				callback();
			}, 200);
		};
		var checkNumber = (rule, value, callback) => {
			setTimeout(() => {
				const reg = /^([1-9][0-9]{0,1}|100)$/
				if (value < 3) {
					callback('支持设备数不能小于3')
				}
				if (!reg.test(value)) {
					callback('支持设备数不能大于100')
				}
				callback()
			}, 200)
		}
		var checkEn = (rule, value, callback) => {
			setTimeout(() => {
				const reg = /^[^\u4e00-\u9fa5\s]*$/gi
				if (!reg.test(value)) {
					callback('Please input English')
				}
				callback()
			}, 200);
		}
		return {
			rules: {
				keyword: [{
						required: true,
						message: '请输入热搜词',
						trigger: 'blur',

					},
					{
						min: 0,
						max: 20,
						message: '热搜词不超过20位',
						trigger: 'blur'
					},
				],
				search_count: [{
						required: true,
						message: '请输入热搜量',
						trigger: 'blur',
					},
					{
						min: 0,
						max: 11,
						message: '热搜量不能超过11位数',
						trigger: 'blur',

					},
					{
						validator: checkIsNumber,
						trigger: 'blur'
					}
				],
			},
			promoRules: {
				amount: [{
						required: true,
						message: '输入积分数量',
						trigger: 'blur'
					},
					{
						validator: checkIsNumber,
						trigger: 'blur'
					}
				],
				name: [{
					required: true,
					message: '输入激活码名称',
					trigger: 'blur'
				}, ],
				usage_limit: [{
						required: true,
						message: '输入可兑数量',
						trigger: 'blur'
					},
					{
						validator: checkIsNumber,
						trigger: 'blur'
					}
				],
				code: [{
					required: true,
					message: '输入激活码',
					trigger: 'blur'
				}, {
					min: 8,
					max: 12,
					message: '兑换码长度在8-12之间',
					trigger: 'blur',

				}, ]
			},
			editPromo: {
				name: [{
					required: true,
					message: '输入激活码名称',
					trigger: 'blur'
				}, ],
				usage_limit: [{
						required: true,
						message: '输入可兑数量',
						trigger: 'blur'
					},
					{
						validator: checkIsNumber,
						trigger: 'blur'
					}
				],
			},
			addPointsRules: {
				amount: [{
						required: true,
						message: "请填写积分",
						trigger: "blur"
					},
					{
						min: 1,
						max: 10,
						message: "长度不超过8",
						trigger: "blur"
					},
					{
						validator: checkIsNumber,
						trigger: 'blur'
					}
				],
			},
			activationRules: {
				name: [{
					required: true,
					message: '请输入名称',
					trigger: 'blur'
				}, {
					min: 1,
					max: 20,
					message: "长度不超过20",
					trigger: "blur"
				}, ],
				expired: [{
					required: true,
					message: '请选择截止时间',
					trigger: 'blur'
				}],
				quantity: [{
					required: true,
					message: '请输入生成数量',
					trigger: 'blur'
				}, {
					validator: checkIsNumberMax,
					trigger: 'blur'
				}],

			},
			applicationRules: {
				activationName: [{
					required: true,
					message: '请输入名称',
					trigger: 'blur'
				}, {
					min: 1,
					max: 10,
					message: "长度不超过10",
					trigger: "blur"
				}],
				activationLimit: [{
					required: true,
					message: '请输入可支持设备数量',
					trigger: 'blur'
				}, {
					validator: checkIsNumber,
					trigger: 'blur'
				}, {
					validator: checkNumber,
					trigger: 'blur'
				}],
				dismissText: [{
					required: true,
					message: '请输入驳回原因',
					trigger: 'blur'
				}],
				dismissTextEn: [{
					required: true,
					message: 'Please input rejection reason',
					trigger: 'blur'
				}, {
					validator: checkEn,
					trigger: 'blur'
				}]
			}
		}
	},
	methods: {
		validateForm(formName, callback) {
			this.$refs[`${formName}`].validate((valid) => {
				if (valid) {
					callback();
				};
			});
		}

	},
}
