<template>
  <div>
    <div>
      <el-table
        :data="listData"
        style="width: 100%"
        header-cell-class-name="table-head"
        cell-class-name="table-cell"
        v-loading="tableLoading"
        class="data-table"
        ref="promoTable"
      >
        <el-table-column label="编号" prop="id" width="120px" show-overflow-tooltip></el-table-column>
        <el-table-column label="名称" prop="name" width="150px" show-overflow-tooltip></el-table-column>
        <el-table-column label="有效期" width="200px" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{timeFormat(scope.row)}}</span>
          </template>
        </el-table-column>

        <el-table-column label="可兑积分" prop="amount" width="150px" show-overflow-tooltip></el-table-column>
        <el-table-column label="可兑数量" prop="usage_limit" width="150px" show-overflow-tooltip></el-table-column>
        <el-table-column label="已兑数量" prop="usage_times" width="150px" show-overflow-tooltip></el-table-column>
        <el-table-column label="兑换码" prop="code" width="150px" show-overflow-tooltip></el-table-column>
        <el-table-column label="状态" prop="status" width="120px" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{statusFormat(scope.row)}}</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" min-width="200px" align="left">
          <!-- 条目操作 -->
          <template slot-scope="scope">
            <div class="icon-btn">
              <el-button
                size="mini"
                type="text"
                v-if="hasResource('coupon_edit')"
                @click="handleEdit(scope.row)"
              >
                <i class="iconfont">&#xe677;</i> 编辑
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 分页 -->
    <div class="ta-r mg-t-18">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-sizes="[10, 20, 50]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import promoApi from "@/api/promotions";
import bus from "./bus";
import { status } from "./var";

export default {
  name: "PromoList",
  data() {
    return {
      tableLoading: false,
      total: 0,
      listData: [],
      currentPage: 1,
      pageSize: 10,
      filter: {},
      status: status,
    };
  },
  computed: {
    queryInfo() {
      const filterKeys = Object.keys(this.filter);
      const query = {
        limit: this.pageSize,
        page: this.currentPage,
        filter: this.filter,
      };
      return query;
    },
  },
  methods: {
    updateList() {
      this.getPromoList();
    },

    getPromoList() {
      this.tableLoading = true;
      promoApi.getPromoList(this.queryInfo).then((res) => {
        if (res.data.code === 10200) {
          this.total = res.data.data.total;
          this.listData = res.data.data.items;
        }
        this.tableLoading = false;
      });
    },

    timeFormat(item) {
      const start = item.effective_at.split(" ")[0];
      const end = item.expired_at.split(" ")[0];
      if (start && end) {
        return start.split("-").join(".") + "-" + end.split("-").join(".");
      } else {
        return "永久";
      }
    },
    statusFormat(item) {
      return this.status[item.status + 1].label;
    },

    handleSizeChange(val) {
      this.pageSize = val;
      this.updateList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.updateList();
    },

    handleEdit(row) {
      this.$emit("handleEdit", row);
    },
  },

  created() {
    bus.$on("makeFilter", (val) => {
      this.filter = val;
      this.currentPage = 1;
      this.updateList();
    });
  },

  mounted() {
    this.getPromoList();
  },
};
</script>