export const status= [
        {
            value:'',
            label:'全部'
        },
        {   
            value: 0,
            label:'已停用'
        },
        {   
            value: 1,
            label:'已启用'
        },
        {
            value:2,
            label:'兑换完'
        }
    ]
export const expiration = [
    {
        value:'',
        label:'全部'
    },
    {   
        value: 0,
        label:'无效期内'
    },
    {   
        value: 1,
        label:'有效期内'
    },

]





