// input检测

const regEmail = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;

const regPassword = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z\S]+$/;

const regNumber = /^[0-9]*$/

const checkEmail = (val = "") => {
  if (!val) return 10;
  let flag = regEmail.test(val);
  if (!flag && val) return 11;
  else return -1;
};

const checkPassword = (val = "") => {
  if (!val) return 20;
  let flag = regPassword.test(val);
  if (!flag && val) return 21;
  else return -1;
};

const checkIsNumber = (val = '') => {
  if (!val) return 30;
  let flag = regNumber.test(val);
  if (!flag && val) return 31;
  else return -1
}

const checkIsNumberMax = (val = '') => {
    if (!val) return 30;
    let flag = regNumber.test(val);
    if (!flag && val) return 32;
    else return -1
}

const checkhasEmoji = (substring = "") => {
  if (substring) {
    var reg = new RegExp("[~#^$@%&!?%*]", 'g');
    if (substring.match(reg)) {
      return 22;
    }
    for (var i = 0; i < substring.length; i++) {
      var hs = substring.charCodeAt(i);
      if (0xd800 <= hs && hs <= 0xdbff) {
        if (substring.length > 1) {
          var ls = substring.charCodeAt(i + 1);
          var uc = ((hs - 0xd800) * 0x400) + (ls - 0xdc00) + 0x10000;
          if (0x1d000 <= uc && uc <= 0x1f77f) {
            return 22;
          }
        }
      } else if (substring.length > 1) {
        var ls = substring.charCodeAt(i + 1);
        if (ls == 0x20e3) {
          return 22;
        }
      } else {
        if (0x2100 <= hs && hs <= 0x27ff) {
          return 22;
        } else if (0x2B05 <= hs && hs <= 0x2b07) {
          return 22;
        } else if (0x2934 <= hs && hs <= 0x2935) {
          return 22;
        } else if (0x3297 <= hs && hs <= 0x3299) {
          return 22;
        } else if (hs == 0xa9 || hs == 0xae || hs == 0x303d || hs == 0x3030 ||
          hs == 0x2b55 || hs == 0x2b1c || hs == 0x2b1b ||
          hs == 0x2b50) {
          return 22;
        }
      }
    }
  }
  return -1;
};

const checkIsSpace = (val = "") => {
  let length = val.split(" ").join("").length;
  if (length === 0) {
    return 22;
  }
  return -1;
}



const checkFormErrorMsg = val => {
  switch (val) {
    case 10:
      return "请输入邮箱";
    case 11:
      return "请输入正确的邮箱";
    case 20:
      return "输入初始密码";
    case 21:
      return "密码必须包含数字，字母组合";
    case 22:
      return "请输入正确的字符";
    case 30:
      return '请输入内容';
    case 31:
      return '请输入正确的数字'
    case 32:
        return '请输入1-100的整数'
    default:
      break;
  }
};
export default {
  checkEmail,
  checkPassword,
  checkFormErrorMsg,
  checkhasEmoji,
  checkIsSpace,
  checkIsNumber,
  checkIsNumberMax
};
