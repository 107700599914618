import axios from '../uitls/require';

export default {
    getPromoList(listInfo) {
        return axios.post('/admin/promotion/list', listInfo)
    },

    getPromoDetail(id) {
        return axios.get('/admin/promotion?id=' + id)
    },

    addNewPromo(codeInfo) {
        return axios.post('/admin/promotion/create', codeInfo)
    },

    updatePromo(codeInfo) {
        return axios.post('/admin/promotion/edit', codeInfo)
    },

    codeGenerate() {
        return axios.get('/admin/promotion/generate-code')
    },

    //用户激励
    getIncentiveList(data) {
        return axios.post('/admin/activity/award/list', data)
    },
    // 导出用户激励数据
    exportList(data) {
        return axios.post('/admin/activity/award/export', data)
    },
    //用户激励详情列表数据
    getUserIncentiveInfo(data) {
        return axios.post('/admin/activity/award/detail ', data)
    },
    //导出用户激励详情列表数据
    exportUserIncentiveInfoList(data) {
        return axios.post('/admin/activity/award/user-export ', data)
    },
    getActivityList(){
        return axios.get('/admin/activity/list')
    },
    // 拉新活动配置列表
    activityConfig() {
        return axios.get('/admin/activity/config')
    },
    // 拉新活动启用/停用
    activityChangeStatus(data) {
        return axios.post('/admin/activity/change-status', data)
    },
    // 拉新活动详情
    getActivityInfo(params = {}) {
        return axios.get('/admin/activity/info', {params})
    },
    // 拉新活动编辑
    activityEdit(data) {
        return axios.post('/admin/activity/edit-introduce', data)
    },
    // 拉新活动规则编辑
    activityEditRule(data) {
        return axios.post('/admin/activity/edit-rule', data)
    },
    // 权限列表
    activityPermissionList(data) {
        return axios.post('/admin/activity/permission/list', data)
    },
    // 设置用户活动权限
    setActivityPermission(data) {
        return axios.post('/admin/activity/permission/set', data)
    },
}
