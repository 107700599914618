<template>
  <div>
    <el-dialog
      top="12%"
      title="编辑兑换码"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="444px"
      :modal-append-to-body="false"
      class="pop-dialog"
    >
      <el-form
        ref="editForm"
        style="width:240px"
        :rules="editPromo"
        :model="editForm"
        label-width="80px"
        label-position="left"
      >
        <el-form-item label="编号" prop="id">
          <el-input
            style="width:240px"
            v-model="editForm.id"
            class="dialog-input"
            :validate-event="false"
            disabled
          />
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input
            style="width:240px"
            v-model="editForm.name"
            class="dialog-input"
            :validate-event="false"
          />
        </el-form-item>

        <el-form-item label="有效期">
          <el-select v-model="permenant" style="width:240px">
            <el-option :value="true" label="永久有效"></el-option>
            <el-option :value="false" label="固定时间"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="固定时间" v-if="!permenant" prop="dates">
          <el-date-picker
            v-model="dates"
            style="width:240px"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            @change="handleDateChange"
            :validate-event="false"
          ></el-date-picker>
        </el-form-item>

        <el-form-item label="可兑积分" prop="amount">
          <el-input
            style="width:240px"
            v-model="editForm.amount"
            class="dialog-input"
            autocomplete="off"
            :validate-event="false"
            disabled
          />
        </el-form-item>

        <el-form-item label="可兑数量" prop="usage_limit">
          <el-input
            style="width:240px"
            v-model="editForm.usage_limit"
            class="dialog-input"
            autocomplete="off"
            :validate-event="false"
            maxlength="5"
          />
        </el-form-item>

        <el-form-item label="已兑数量" prop="usage_times">
          <el-input
            style="width:240px"
            v-model="editForm.usage_times"
            class="dialog-input"
            autocomplete="off"
            :validate-event="false"
            disabled
          />
        </el-form-item>

        <el-form-item label="兑换码" prop="code">
          <el-input
            style="width:240px"
            v-model="editForm.code"
            class="dialog-input"
            autocomplete="off"
            :validate-event="false"
            disabled
          />
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" class="confirm-button" @click="handleEdit" size="medium" :loading="btnLoading">确认</el-button>
        <el-button @click="hide" class="cancel-button">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import promoApi from "@/api/promotions";
import formValidations from "@/mixins/form-validations";
import bus from "../bus";

export default {
  name: "AddNewDialog",
  mixins: [formValidations],
  props: {
    updateList: {
      type: Function,
      default: () => {
        return Function();
      },
    },
  },

  data() {
    return {
      permenant: true,
      dates: [],
      editForm: {
        id: "",
        name: "",
        type: 1,
        code: "",
        amount: "",
        usage_limit: "",
        usage_times: "",
        effective_at: "",
        expired_at: "",
        market: "US",
      },
      dialogVisible: false,
      btnLoading:false
    };
  },

  methods: {
    clear() {
      this.clearForm("editForm");
      this.dates = [];
    },

    show(item) {
      this.dialogVisible = true;
      this.getItemInfo(item.id);
    },

    hide() {
      this.dialogVisible = false;
      this.clear();
    },

    handleDateChange(val) {
      this.dates = val;
      if (val) {
        const startDate = val[0] + " 00:00:00";
        const endDate = val[1] + " 23:59:59";
        this.editForm.effective_at = startDate;
        this.editForm.expired_at = endDate;
      } else {
        (this.editForm.effective_at = ""), (this.editForm.expired_at = "");
      }
    },

    updatePromo() {
      if (this.editForm.usage_limit < this.editForm.usage_times) {
        this.$$error("可兑数量不能低于已兑数量");
        return;
      }
      this.btnLoading=true;
      promoApi.updatePromo(this.editForm).then((res) => {
        if (res.data.code === 10200) {
          this.$$success("更改成功！");
          this.hide();
          this.updateList();
        } else {
          this.$$error(res.data.message);
        }
        this.btnLoading=false
      });
    },

    getItemInfo(id) {
      promoApi.getPromoDetail(id).then((res) => {
        if (res.data.code === 10200) {
          this.editForm = res.data.data;
          this.editForm.amount = Math.floor(this.editForm.amount);
          this.dates = [res.data.data.effective_at, res.data.data.expired_at];
          this.permenant = this.dates[0] && this.dates[1] ? false : true;
        }
      });
    },

    handleEdit() {
      this.validateForm("editForm", () => this.updatePromo());
    },

    mounted() {
      this.getItemInfo();
    },
  },
};
</script>