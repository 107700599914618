<template>
    <div v-if="hasResource('coupon_management')">
        <div class="search-form">
            <el-button  class="black-btn btn-l" v-if="hasResource('coupon_add')" @click="handleAddNew">新增</el-button>
            <promo-search @updateList='updateList'></promo-search>
        </div>
        <promo-list ref='promoList' @handleEdit='handleEdit'></promo-list>
        <add-new-dialog :updateList='updateList' ref="addNewPop"></add-new-dialog>
        <edit-dialog :updateList='updateList' ref='editPop'></edit-dialog>
        
    </div>
</template>
<script>
import {promoSearch, promoList, addNewDialog, editDialog} from './components/components-index'

export default {
    name:'PromoManage',
    components:{promoSearch, promoList, addNewDialog, editDialog},
    data() {
        return {
           
        }
    },
    methods: {
        handleAddNew() {
            this.$nextTick(() => {
                this.$refs['addNewPop'].show()
            })
        },

        handleEdit(val) {
            this.$nextTick(()=> {
                this.$refs.editPop.show(val)
            })
        },

        updateList() {
            this.$nextTick(()=> {
                this.$refs.promoList.updateList()
            })    
        },

    },
    mounted() {
    },
}
</script>