<template>
  <div class="dis-fix">
    <el-form ref="filterRef" :model="queryForm" :inline="true">
      <el-form-item label="编号/名称" prop="id_name">
        <el-input v-model="queryForm.id_name" clearable placeholder="输入编号/名称检索"></el-input>
      </el-form-item>
      <el-form-item label="兑换码" prop="code">
        <el-input v-model="queryForm.code" clearable placeholder="输入兑换码检索"></el-input>
      </el-form-item>

      <el-form-item prop="status" label="状态">
        <el-select v-model="queryForm.status">
          <el-option
            v-for="item in status"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item prop="expiration" label="有效期">
        <el-select v-model="queryForm.expiration">
          <el-option
            v-for="item in expiration"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div class="text-btn">
      <span class="serach-button" @click="send">查询</span>
      <span class="clear-button" @click="clearSearch">清空</span>
    </div>
  </div>
</template>
<script>
import bus from "./bus";
import { status, expiration } from "./var";

export default {
  name: "PromoSearch",
  data() {
    return {
      queryForm: {
        id_name: "",
        code: "",
        status: "",
        expiration: "",
      },
      status: status,
      expiration: expiration,
    };
  },
  methods: {
    clearSearch() {
      this.clearForm("filterRef");
    },
    send() {
      const form = this.queryForm;
      bus.$emit("makeFilter", form);
    },
  },
};
</script>